import React, { FunctionComponent, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { CheckupRequestInfo } from '@companion-professional/dctypes';
import { HeaderBar } from './components/HeaderBar';
import { CheckupLanding } from './pages/CheckupLanding';
import { Checkup } from './pages/Checkup';
import { CheckupComplete } from './pages/CheckupComplete';
import { AndroidHelp } from './pages/AndroidHelp';
import { IOSHelp } from './pages/IOSHelp';
import { useSetFeatureFlagContext } from './hooks/useSetFeatureFlagContext';
import { cn } from '@companion-professional/webutils';
import { useCameraPreviewEnabled } from './state/camera';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

interface CheckupRoutesProps {
  checkupRequest: CheckupRequestInfo;
}

// CheckupRoutes contains all the routes for the checkup flow.
export const CheckupRoutes: FunctionComponent<CheckupRoutesProps> = ({ checkupRequest }) => {
  useSetFeatureFlagContext(checkupRequest.clinic_id);
  const cameraPreviewEnabled = useCameraPreviewEnabled();

  useEffect(() => {
    Sentry.withScope((scope) => {
      scope.setTag('checkup_id', checkupRequest.id);
      return;
    });
  }, [checkupRequest.id]);

  return (
    <div
      className={cn('flex w-full flex-1 flex-col items-center justify-between overflow-y-auto', {
        'bg-brand-background': !cameraPreviewEnabled,
        // This is a hack to make the camera preview take up the full screen and push the header (tabs) off the screen.
        'landscape:h-[101vh]': cameraPreviewEnabled
      })}
    >
      {cameraPreviewEnabled ? null : <HeaderBar />}
      <SentryRoutes>
        <Route index element={<CheckupLanding checkupRequest={checkupRequest} />} />
        <Route path="/checkup" element={<Checkup checkupRequest={checkupRequest} />} />
        <Route path="/complete" element={<CheckupComplete />} />
        <Route path="/android-help" element={<AndroidHelp />} />
        <Route path="/ios-help" element={<IOSHelp />} />
      </SentryRoutes>
    </div>
  );
};
