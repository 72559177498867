import React, { FunctionComponent } from 'react';
import { CheckCircleIcon } from 'lucide-react';
import { SplitLayout } from '../../components/SplitLayout';
import { useSetHeaderBarTitle } from '../../state/headerBar';

// CheckupComplete is the page that is shown to the Pet Parent when they have completed the checkup.
export const CheckupComplete: FunctionComponent = () => {
  const setHeaderBarTitle = useSetHeaderBarTitle();
  setHeaderBarTitle('Checkup Complete');

  return (
    <SplitLayout>
      <div className="flex flex-col items-center justify-center">
        <CheckCircleIcon className="mb-4 h-24 w-24 text-primary" />
        <div className="text-xl font-bold text-primary md:text-2xl">Checkup Complete!</div>
      </div>
    </SplitLayout>
  );
};
