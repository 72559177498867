import React, { FunctionComponent, useState, useEffect, useRef, useCallback } from 'react';
import { UpChunk } from '@mux/upchunk';
import { MuxUploaderDisplay } from './MuxUploaderDisplay';

// DEFAULT_CHUNK_SIZE is the default chunk size that the file will be uploaded in.  Currently set to 10 MB.
// This is used in the Mux's UpChunk options chunkSize value.  From the upchunk docs:
//  > The size in kB of the chunks to split the file into, with the exception of the final chunk which may be smaller.
//  > This parameter must be in multiples of 256.
const DEFAULT_CHUNK_SIZE = 10240;

interface UploaderProps {
  // file is a File object that will be uploaded to Mux
  file: File;

  // onReset is a function that will be called when the user clicks the reset button.
  onReset: () => void;

  // onUploadComplete is a function that will be called when the upload is complete.
  onUploadComplete?: () => void;

  // signedUploadUrl the signed MUX upload URL. This is directly used in the UpChunk.createUpload function (the
  // endpoint parameter).
  signedUploadUrl: string;

  // chunkSize is the size (in Kb) of the chunks that the file will be uploaded.  NOTE: This parameter must be in
  // multiples of 256.
  chunkSize?: number;

  // allowPostUploadReset is a flag that determines if the user should be allowed to reset the upload after it's
  // completed.  (defaults to false)
  allowPostUploadReset?: boolean;

  // onError is a function that will be called when an error occurs during the upload process.  It will be passed a
  // CustomEvent object with the error in the detail field.
  onError?: (error: CustomEvent<any>) => void;
}

// MuxUploader is a component that uses Mux's upchunk library to upload a file to Mux.
// TODO: Add more sophisticated error handling.  Currently, it just logs to the console and displays some text.
export const MuxUploader: FunctionComponent<UploaderProps> = ({
  file,
  onReset,
  signedUploadUrl,
  onUploadComplete = () => {},
  onError = () => {},
  chunkSize = DEFAULT_CHUNK_SIZE,
  allowPostUploadReset = false
}) => {
  const [progress, setProgress] = useState(0);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [statusMessage, setStatusMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const isUploading = useRef(false);

  // handleUpload creates an upload object and subscribes to events.  This is where the actual upload happens.
  const handleUpload = useCallback(async () => {
    const upload = UpChunk.createUpload({
      endpoint: signedUploadUrl,
      file,
      chunkSize
    });

    upload.on('error', (error) => {
      if (typeof error.detail === 'string') {
        setStatusMessage(error.detail);
      } else {
        setStatusMessage('Upload Error');
      }
      onError(error);
    });

    upload.on('progress', (progress) => {
      setProgress(progress.detail);
    });

    upload.on('success', () => {
      onUploadComplete();
      setFileUploaded(true);
      setStatusMessage('Upload Complete');
    });
  }, [signedUploadUrl, file, chunkSize, onUploadComplete]);

  useEffect(() => {
    if (!isUploading.current) {
      isUploading.current = true;
      handleUpload().catch((err) => {
        console.log(err);
        setErrorMessage('Generic upload error');
      });
    }
  }, []);

  return (
    <MuxUploaderDisplay
      progress={progress}
      onReset={onReset}
      statusMessage={statusMessage}
      errorMessage={errorMessage}
      complete={fileUploaded}
      allowPostUploadReset={allowPostUploadReset}
    />
  );
};
