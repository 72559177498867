import React, { FunctionComponent } from 'react';
import { Button, ButtonProps } from '@companion-professional/components';
import { cn } from '@companion-professional/webutils';

interface BottomButtonProps extends ButtonProps {}

// BottomButton is a button that is always at the bottom of the screen.  It is used for the primary action on a page.
export const BottomButton: FunctionComponent<BottomButtonProps> = ({ children, className, ...props }) => {
  return (
    <div className={cn('my-8 flex w-full flex-row justify-center', className)}>
      <Button variant="primary" size="large" className="mx-4 w-full max-w-lg" {...props}>
        {children}
      </Button>
    </div>
  );
};
