import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  createRoutesFromChildren,
  HashRouter as Router,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Hotjar from '@hotjar/browser';
import { isAxiosError } from 'axios';
import { getEnvironmentFromLocation } from '@companion-professional/webutils';
import { AppWithLDProvider } from './App';
import './tailwind.css';

declare const __SENTRY_DSN__: string;
declare const __VERSION__: string;
declare const __COMMIT_HASH__: string;

Sentry.init({
  dsn: __SENTRY_DSN__,
  environment: getEnvironmentFromLocation('companionpro.biz', 'checkup'),
  release: `${__VERSION__}-${__COMMIT_HASH__}`,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    }),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    })
  ],

  // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a
  // lower rate in production.
  replaysSessionSampleRate: 0.1,

  // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where
  // errors occur.
  replaysOnErrorSampleRate: 1.0,

  // tracesSampleRate is to 1.0 to capture 100% of transactions for performance monitoring.  We can adjust this value
  // later if we want to sample transactions.
  tracesSampleRate: 1.0,

  // tracePropagationTargets controls which URLs distributed tracing should be enabled on.
  tracePropagationTargets: ['localhost', /^https:\/\/checkup\.(.*\.)?companionpro\.biz/i]
});

// Initialize Hotjar
// Settings for this site can be updated here: https://insights.hotjar.com/site/3912755/settings#site-information
declare const __HOTJAR_SITE_ID__: number;
declare const __HOTJAR_VERSION__: number;
Hotjar.init(__HOTJAR_SITE_ID__, __HOTJAR_VERSION__);

// MAX_RETRIES is the maximum number of times a query will be retried before giving up.
const MAX_RETRIES = 3;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (failureCount > MAX_RETRIES) {
          return false;
        }

        // Don't retry 404 errors
        return !(isAxiosError(error) && error.response?.status === 404);
      }
    }
  }
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <Router>
          <AppWithLDProvider />
        </Router>
        {/*<ReactQueryDevtools initialIsOpen={false} />*/}
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);
