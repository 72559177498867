import React, { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VideoCaptureUploader } from '../../components/VideoCaptureUploader';
import { BottomButton } from '../../components/BottomButton';
import { CaptureWithVideoInstructions } from '../../components/CaptureWithVideoInstructions';
import { CheckupRequestInfo } from '@companion-professional/dctypes';
import { useCameraPreviewEnabled, useSetCameraPreviewEnabled } from '../../state/camera';

// The following are used for displaying the instructions video (encoded in Mux).  If we want to change the video, we
// will need to update these values.  The video can be uploaded by using Mux's web interface located here:
// https://dashboard.mux.com/organizations/4od8hp/environments/n3bo4i/video/assets#create  All the default options
// should be fine (encoding = smart, max resolution = 4k, and generate captions set to disabled).  After the video is
// uploaded, the playback ID can be found in the video's settings.  The poster image is just this url (with the
// playback ID): https://image.mux.com/{playback_id}/thumbnail.png?time=15&width=480
// https://dashboard.mux.com/organizations/4od8hp/environments/n3bo4i/video/assets/imtljanva2cDdIMhhr2scfEm01XVysbLlJF4eKzyOcDM/build
const instructionVideoMuxPlaybackId = 'rVedvh6i02QCTJw2wRxZin6VgWkWZLBnLTd6fMQHfx7E';
const instructionVideoPoster =
  'https://image.mux.com/rVedvh6i02QCTJw2wRxZin6VgWkWZLBnLTd6fMQHfx7E/thumbnail.png?width=480&time=22';

interface CheckupProps {
  // checkupRequest is the checkup request that is being fulfilled.
  checkupRequest: CheckupRequestInfo;
}

// Checkup is the heart of the checkup capture flow.  It is responsible for allowing the user to add a video/photo to
// the checkup.  It also has a form area to capture any additional information about the checkup.
export const Checkup: FunctionComponent<CheckupProps> = ({ checkupRequest }) => {
  const navigate = useNavigate();
  const setCameraPreviewEnabled = useSetCameraPreviewEnabled();
  const cameraPreviewEnabled = useCameraPreviewEnabled();

  // formReady is set to true when the form is ready to be submitted (i.e. the video has been uploaded).
  const [formReady, setFormReady] = useState(false);

  // This is here to support only one file per request.
  const [oneFile, setOneFile] = useState<File | null>(null);

  return (
    <div className="flex w-full flex-1 flex-col justify-between">
      <div className="flex w-full flex-1 flex-col items-center">
        {oneFile ? (
          <VideoCaptureUploader
            itemId={checkupRequest?.requests?.[0]?.item_id}
            file={oneFile}
            onReset={() => {
              setOneFile(null);
            }}
            onUploadComplete={() => {
              setFormReady(true);
            }}
          />
        ) : (
          <CaptureWithVideoInstructions
            instructionVideoMuxPlaybackId={instructionVideoMuxPlaybackId}
            instructionVideoPoster={instructionVideoPoster}
            onVideoSelected={(file) => {
              setOneFile(file);
              setCameraPreviewEnabled(false);
            }}
          />
        )}
      </div>
      {formReady && !cameraPreviewEnabled ? (
        <BottomButton
          onClick={() => {
            // Currently, this just navigates to the complete page.  Eventually, this will submit the form with extra
            // information.
            navigate('../complete');
          }}
        >
          Submit Checkup
        </BottomButton>
      ) : null}
    </div>
  );
};
