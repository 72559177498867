import React, { FunctionComponent } from 'react';
import { ProgressCircle } from '../ProgressCircle';
import { Button } from '../Button';
import { ErrorAlert } from '../ErrorAlert';

interface MuxUploaderDisplayProps {
  progress?: number | null;
  onReset?: () => void;
  errorMessage?: string | null;
  statusMessage?: string | null;
  complete?: boolean;
  allowPostUploadReset?: boolean;
}

export const MuxUploaderDisplay: FunctionComponent<MuxUploaderDisplayProps> = ({
  progress,
  onReset,
  errorMessage,
  statusMessage,
  complete = false,
  allowPostUploadReset = false
}) => {
  const progressWholeNumber = Math.floor(progress || 0);

  return (
    <div className="relative flex flex-col items-center justify-center">
      <div className="flex flex-row">
        <ProgressCircle value={progressWholeNumber} />
      </div>
      {errorMessage ? (
        <ErrorAlert title="Error" message={errorMessage} />
      ) : (
        <div className="text-center text-sm text-gray-800">{statusMessage}</div>
      )}
      {!complete || allowPostUploadReset ? (
        <Button variant="destructive" onClick={onReset} className="my-4">
          {complete ? 'Reset' : 'Stop Upload'}
        </Button>
      ) : null}
    </div>
  );
};
