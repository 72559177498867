import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Button, QueryLoaderWrapper } from '@companion-professional/components';
import * as Sentry from '@sentry/react';
import { VideoCaptureWrapper } from '../VideoCaptureWrapper';
import { Uploader } from './Uploader';
import { getItemUploadUrl } from '../../lib/api';
import { useSetHeaderBarTitle } from '../../state/headerBar';

interface VideoCaptureUploaderProps {
  // file is the file to be uploaded.
  file: File;

  // onReset is a callback that is called when the user clicks the reset button.
  onReset: () => void;

  // itemId is the item id that the video is associated with.  This is used to get the signed upload url.
  itemId?: string;

  // onUploadComplete is a callback that is called when the upload is complete.
  onUploadComplete: () => void;

  // autoUpload is a flag that determines if the file should be uploaded automatically once the component is mounted.
  // (defaults to true)
  autoUpload?: boolean;
}

// VideoCaptureUploader is used to upload a video file to Mux.  It's designed to automatically upload the file once
// it's mounted, but there is an option to turn this off (setting autoUpload to false).  Currently, the manual upload
// button is not implemented.
export const VideoCaptureUploader: FunctionComponent<VideoCaptureUploaderProps> = ({
  file,
  onReset,
  itemId,
  onUploadComplete,
  autoUpload = true
}) => {
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState<Error | null | undefined>(null);
  const [signedUploadUrl, setSignedUploadUrl] = useState<string | null>(null);
  const [isUploadMode, setIsUploadMode] = useState<boolean>(false);
  const [videoAlreadyUploaded, setVideoAlreadyUploaded] = useState<boolean>(false);
  const setTitle = useSetHeaderBarTitle();
  setTitle('Uploading Video');

  // activeItemId is used to keep track of the current item id.  This is used to prevent multiple calls to the
  // getItemUploadUrl function.  This mostly happens when in local development mode and React re-renders the component
  // multiple times as part of its strict mode.
  const activeItemId = useRef<string>();
  useEffect(() => {
    if (activeItemId.current === itemId || !itemId) {
      return;
    }
    activeItemId.current = itemId;
    setIsPending(true);
    getItemUploadUrl(itemId)
      .then(({ url, video_already_uploaded }) => {
        setSignedUploadUrl(url);
        setVideoAlreadyUploaded(video_already_uploaded);
        if (autoUpload && !video_already_uploaded) {
          setIsUploadMode(true);
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        setError(new Error('There was an issue uploading the video.  Please try again.'));
      })
      .finally(() => {
        setIsPending(false);
      });
  }, [itemId]);

  return (
    <div className="flex w-full flex-1 flex-col items-center justify-center">
      <QueryLoaderWrapper isPending={isPending} error={error}>
        {isUploadMode ? (
          <Uploader
            file={file}
            onReset={onReset}
            signedUploadUrl={signedUploadUrl}
            onUploadComplete={onUploadComplete}
          />
        ) : (
          <VideoCaptureWrapper>
            <div className="my-4 flex flex-col items-center gap-4">
              <div className="flex h-16 items-center justify-center text-xl">
                <div className="text-center font-bold">
                  {videoAlreadyUploaded
                    ? 'Are you sure you want to replace the video you previously uploaded?'
                    : 'Upload Video?'}
                </div>
              </div>
              <div className="mb-5 flex flex-row items-center justify-center gap-4">
                <Button className="py-4" variant="primary" onClick={() => setIsUploadMode(true)}>
                  Yes
                </Button>
                <Button className="py-4" variant="destructive" onClick={onReset}>
                  No
                </Button>
              </div>
            </div>
          </VideoCaptureWrapper>
        )}
      </QueryLoaderWrapper>
    </div>
  );
};
