import axios from 'axios';
import { CheckupRequestInfo, ItemUploadSignedUrl } from '@companion-professional/dctypes';
import { CHECKUP_REQUEST_URL, GENERATE_UPLOAD_URL } from './config';

// This file contains all the API HTTP requests for the application.

// getCheckupRequest is a function that fetches a single checkup request.
export const getCheckupRequest = async (checkupRequestId: string): Promise<CheckupRequestInfo> => {
  const response = await axios.get<CheckupRequestInfo>(`${CHECKUP_REQUEST_URL}/${checkupRequestId}`);
  return response.data;
};

// getItemUploadUrl is a function that fetches a signed URL for uploading a video to MUX
export const getItemUploadUrl = async (itemId: string): Promise<ItemUploadSignedUrl> => {
  const response = await axios.post<ItemUploadSignedUrl>(`${GENERATE_UPLOAD_URL}/${itemId}`);
  return response.data;
};
