import React, { FunctionComponent } from 'react';
import { useHeaderBarTitle } from '../../state/headerBar';

export const HeaderBar: FunctionComponent = () => {
  const headerBarTitle = useHeaderBarTitle();
  return (
    <div
      className={
        'w-full border-b-2 border-widget-foreground bg-primary py-2 text-center text-base font-medium text-primary-foreground md:text-xl'
      }
    >
      {headerBarTitle}
    </div>
  );
};
