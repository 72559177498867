import React, { FunctionComponent } from 'react';
import { Route, Routes } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { Home } from './pages/Home';
import { GenerateError } from './components/GenerateError';
import { CheckupLoader } from './CheckupLoader';

declare const __LAUNCH_DARKLY_CLIENT_ID__: string;

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const App: FunctionComponent = () => {
  return (
    <SentryRoutes>
      <Route index element={<Home />} />
      <Route path="/generate-error" element={<GenerateError />} />
      <Route path="/:checkupId/*" element={<CheckupLoader />} />
    </SentryRoutes>
  );
};

// AppWithLDProvider is a wrapper around the App component that provides the LaunchDarkly context. This is required for
// LaunchDarkly to work.
export const AppWithLDProvider = withLDProvider({
  clientSideID: __LAUNCH_DARKLY_CLIENT_ID__
})(App);
