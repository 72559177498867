import React, { FunctionComponent, useEffect, useState } from 'react';
import { XCircle, VideoOff } from 'lucide-react';
import { cn } from '@companion-professional/webutils';
import { useMediaRecorder } from './useMediaRecorder';
import { RecordingControls } from './RecordingControls';
import { CameraCaptureStates, VideoRecordingStats } from './inBrowserCameraTypes';
import { DeviceSettings } from './DeviceSettings';
import { VideoTime } from './VideoTime';
import { LoaderSpinner } from '../LoaderSpinner';
import { PreviewControls } from './PreviewControls';
import { Button } from '../Button';

// TODO: For some reason, the video is not showing up (i.e. is black) when the checkup app is launched using the IOS
//  QR code scanner.  We should investigate why this is happening and fix it.  NOTE: scanning the QR code with the
//  camera app works fine.

interface InBrowserCameraProps {
  // onUploadVideo is a function that is called when the user clicks the "Upload" button.  It is passed the File that
  // was created from the finalBlob along with the stats of the video that was recorded.
  onUploadVideo?: (file: File, stats: VideoRecordingStats) => void;

  // onBack is a function that is called when the user clicks the "Back" button (e.g. it can be used to navigate back
  // to the previous screen).
  onBack?: () => void;

  // showErrorScreen is a boolean that determines whether to show the error screen.  This is useful for testing the
  // error state of the component (i.e. looking at the error state in Storybook).
  showErrorScreen?: boolean;

  // onError is a function that is called if an error occurs.  It is passed the error message.
  onError?: (error: string) => void;
}

// InBrowserCamera is a component that allows the user to record a video (using their camera) and then upload it.
export const InBrowserCamera: FunctionComponent<InBrowserCameraProps> = ({
  onUploadVideo = () => {},
  onBack = () => {},
  onError = () => {},
  showErrorScreen = false
}) => {
  const {
    videoRef,
    finalBlobRef,
    cameraCaptureState,
    recordingStartTime,
    recordingStopTime,
    error,
    devices,
    startCamera,
    stopCamera,
    startRecording,
    stopRecording
  } = useMediaRecorder();
  const [videoDeviceId, setVideoDeviceId] = useState<string | undefined>();

  useEffect(() => {
    stopCamera();
    startCamera(videoDeviceId).catch((err) => {
      console.error('Error starting camera', err);
    });
  }, [videoDeviceId]);

  useEffect(() => {
    // This will pass an error message coming from the useMediaRecorder hook to the parent component.
    if (error) {
      onError(error);
    }
  }, [error]);

  const submitRecording = () => {
    if (!finalBlobRef.current) {
      console.error('Cannot submit recording without a blob');
      return;
    }
    const createdFile = new File([finalBlobRef.current], 'video-from-camera', { type: finalBlobRef.current.type });
    onUploadVideo(createdFile, {
      size: createdFile.size,
      type: createdFile.type,
      duration: (recordingStopTime || 0) - (recordingStartTime || 0),
      width: videoRef.current?.videoWidth,
      height: videoRef.current?.videoHeight
    });
  };

  if (error || showErrorScreen) {
    // TODO: Add more specific/helpful error handling https://app.clickup.com/t/86898w0vt
    return (
      <div className="flex w-full flex-1 flex-col items-center justify-center gap-4 text-destructive">
        <VideoOff size="48" />
        <div className="m-6 text-lg font-bold">Something went wrong accessing your camera.</div>
        <Button
          onClick={() => {
            stopCamera();
            onBack();
          }}
          size="small"
          variant="primary"
          className="ml-2"
        >
          Back
        </Button>
      </div>
    );
  }

  // TODO: The bottom-[15%] class is used to position our extra recording and preview controls at the bottom of the
  //  video preview, but above the video controls.  A possible better way of doing this would be to make a custom video
  //  player component that includes the recording controls.  That way we'd have complete control over the layout and
  //  styling of the video player and recording controls.
  return (
    <div className="flex w-full flex-1 flex-col items-center justify-center object-contain">
      {cameraCaptureState === CameraCaptureStates.LOADING ? (
        <div className="flex flex-1 items-center justify-center">
          <LoaderSpinner size="large" />
        </div>
      ) : null}

      <div
        className={cn('relative flex flex-1 flex-row items-center justify-center', {
          hidden: cameraCaptureState === CameraCaptureStates.LOADING
        })}
      >
        <div className="relative">
          <video ref={videoRef} autoPlay playsInline muted className="relative" />
          <RecordingControls
            className="absolute bottom-[15%] left-0 right-0 flex flex-col items-center justify-end"
            cameraCaptureState={cameraCaptureState}
            startRecording={startRecording}
            stopRecording={stopRecording}
          >
            <VideoTime recordingStartTime={recordingStartTime} />

            <DeviceSettings
              devices={devices}
              onVideoDeviceIdChange={setVideoDeviceId}
              currentVideoDeviceId={videoDeviceId}
            />

            <XCircle
              size={30}
              color="white"
              className="hover:bg-secondary"
              onClick={() => {
                stopCamera();
                onBack();
              }}
            />
          </RecordingControls>

          <PreviewControls
            className="absolute bottom-[15%] left-0 right-0 flex flex-col items-center justify-end"
            cameraCaptureState={cameraCaptureState}
            submitRecording={submitRecording}
            startOver={async () => {
              stopCamera();
              await startCamera(videoDeviceId);
            }}
          />
        </div>
      </div>
    </div>
  );
};
