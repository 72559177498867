import React, { FunctionComponent } from 'react';
import * as Sentry from '@sentry/browser';
import { MuxUploader } from '@companion-professional/components';
import { VideoCaptureWrapper } from '../VideoCaptureWrapper';

interface UploaderProps {
  signedUploadUrl?: string | null;
  file: File;
  onReset: () => void;
  onUploadComplete: () => void;
}

// Uploader is a component that wraps the MuxUploader component with some minimal UI.  Once this component is mounted,
// it will automatically upload the file to Mux (using the signed upload URL).
export const Uploader: FunctionComponent<UploaderProps> = ({ file, onReset, signedUploadUrl, onUploadComplete }) => {
  if (!signedUploadUrl) {
    return null;
  }

  return (
    <VideoCaptureWrapper>
      <div className="flex h-16 items-center justify-center text-xl">
        <div className="text-center font-bold">Uploading Video</div>
      </div>
      <div className="flex flex-1 flex-col items-center justify-center">
        <MuxUploader
          file={file}
          onReset={onReset}
          signedUploadUrl={signedUploadUrl}
          onUploadComplete={onUploadComplete}
          onError={(err) => {
            // Make sure we log this error to Sentry
            Sentry.captureException(err);
            console.error('Error uploading video', err);
          }}
        />
      </div>
    </VideoCaptureWrapper>
  );
};
